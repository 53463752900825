@import url('https://fonts.googleapis.com/css?family=News+Cycle&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap');

html, body {
    height: 100%;
    width: 100%;
    font-family: 'News Cycle', sans-serif;
    font-size: 1.1em;
}

html {
    overflow-x: hidden;
    width: 100vw;
}

body {
    overflow: auto;
    /*position: fixed;
    overflow: hidden;*/
}

h1 {
    font-size: 100%;
}

h3 {
    border-bottom: #000 solid 1px;
    padding: 0 5px 5px;
}

h5 {
    text-align: center;
    padding: 10px 0px;
}

a {
    color: white;
}

a:active {
    color: white;
}

a:visited {
    color: white;
}

a:hover {
    text-decoration: none;
    cursor: pointer;
    color: #CCC;
}

p {
    padding: 10px 30px;
}

ul li {
    list-style-type: none;
}

.social-img {
    background-color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 5px;
}

#welcome {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#welcome h1 img {
    border-radius: 50%;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    -webkit-transition: -webkit-transform 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    width: 20vw;
    margin: 0 10px;
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

#welcome h1 img:hover {
    margin: 0 5px;
    width: calc(20vw + 10px);
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: all .4s ease-in-out;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}

#welcome h1 {
    height: 20vh;
    min-height: 120px;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    text-align: center;
    color: white;
    font-family: 'Roboto Slab', serif;
}

.overlay {
    display: flex;
}

.overlay > h1 {
    display: flex;
}

.overlay > h1 > a,
.overlay > h1 > p {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: flex;
}

.overlay > h1 > p {
    padding: 0;
    margin: 0;
    flex: 1;
}

.overlay > h1 > p > a {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: block;
    width: 100%;
}

.overlay > h1 > p.right > a {
    text-align: left;
    padding-right: 10px;
}

.overlay > h1 > p.left > a {
    text-align: right;
    padding-left: 10px;
}

#about {
    background: url('img/la.jpg') no-repeat fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    min-width: 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#about .bio > img {
    border-radius: 50%;
    display: block;
    margin: 0 auto;
    width: 30vh;
}

.bio {
    padding-top: 10vh;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.bio h3 {
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 25vw;
    margin: 0 auto;
    text-align: center;
    font-size: 2.5vw;
}

.bio h3 > a > img {
    width: 1.8vw;
    height: 1.8vw;
}

.bio .description {
    width: 80%;
    font-size: 1.7vw;
}

#about .bio {
    background: rgba(32, 32, 32, 0.8) fixed;
    background-size: cover;
    width: 70vw;
    flex-direction: column;
    align-items: center;
}

#about p {
    margin: auto;
    width: 100%;
    text-align: justify;
    color: #fff;
}

#gallery {
    background-color: #FFFFFF;
    width: 100%;
}

#gallery img {
    display: block;
    width: 100%;
}

#container {
}

.container-fluid {
    background-image: url('img/home_bg.jpg');
    background-position: 15% bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #000000;
    width: 100%;
    height: 100%;
    padding: 0px;
    display: flex;
}

.nav-links {
    z-index: 10;
    float: right;
    right: 0px;
    top: -10px;
    background: rgba(32, 32, 32, 0.9);
    padding: 20px 15px 15px;
    border-radius: 0px 0px 0px 10px;
    position: fixed;
}

.nav-links > a {
    color: #dedede;
    text-decoration: none;
}

.nav-links > a:visited {
    color: #dedede;
    cursor: default;
    pointer-events: none;
    text-decoration: none;
    background-color: black !important;
}

.nav-links > a:hover {
    color: #fff;
    text-decoration: none;
}

.quote {
    font-style: italic;
    color: #fff;
    font-size: 1.1em;
    background: radial-gradient(rgba(0, 0, 0, 0.6), rgba(255, 255, 255, 0.4));
    border-radius: 5px;
    display: block;
    margin: auto;
    text-align: center;
    padding: 20px 10px;
    width: 70%;
}

.gallery-bg {
    background: url('img/wood.jpg') fixed;
    background-size: cover;
    height: 30vh;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.gallery-bg > h1 {
    text-align: left;
    font-size: 3em;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #FFFFFF;
    padding-left: 10%;
}

.art {
    margin-right: 30px;
}

.middle {
    display: flex;
    display: -webkit-flex;
    height: 200px;
    width: 50%;
    margin: auto;
}

.middle p {
    margin: auto; /* Important */
    text-align: justify;
    padding: 15px 40px 15px 15px;
}

.hidden {
    display: none;
}

.card {
    display: inline-block;
    margin: 20px;
}


/*      MODAL THINGS        */

.preview {
    transition: 0.3s;
}

.preview:hover {opacity: 0.7;}

/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 10; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

/* Modal Content (image) */
.modal-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
}

/* Caption of Modal Image */
.caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #fff;
    padding: 10px 0;
    height: 150px;
}

/* Add Animation */
.modal-content, .caption {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
    from {-webkit-transform:scale(0)}
    to {-webkit-transform:scale(1)}
}

@keyframes zoom {
    from {transform:scale(0)}
    to {transform:scale(1)}
}

/* The Close Button */
.close {
    position: absolute;
    top: 10%;
    left: 20%;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
    .modal-content {
        width: 100%;
    }
}

#parent {
    margin: 0;
    display: flex;
    flex-direction: row;
    position: relative;
}

#categories-content {
    text-align: justify;
    margin: 0;
    display: flex;
    font-size: min(16px, 4vw);
}

#categories-content .card {
    min-width: 45%;
}

#categories-nav {
    background-color: #333;
    color: #fff;
    font-size: min(16px, 4vw);
    position: sticky;
    align-self: flex-start;
    justify-content: space-between;
    height: auto;
    top: 65px;
    overflow-y: auto;
    z-index: 5;
}

.categories-nav {
    border-radius: 0px 0px 0px 10px;
    min-width: 140px;
}

.categories-nav ul {
    width: 100%;
    padding: 0px;
}

.categories-nav ul li {
    color: #dedede;
    width: 100%;
    padding: 5px 0px 5px 20px;
    border-bottom: 2px solid #333;
    border-top: 2px solid #333;
}

.categories-nav ul li:hover {
    color: #fff;
    background-color: #222;
    border-bottom: 2px solid #222;
    border-top: 2px solid #222;
    cursor: pointer;
}

.categories-nav ul li.selected {
    color: #fff;
    background-color: #222;
    border-bottom: 2px solid #333;
    border-top: 2px solid #333;
    cursor: default;
}

.fixed {
    position: fixed;
    z-index: 5;
    right: -12px;
}

